import { FC, useCallback, useEffect, useRef, useState } from "react";
import classes from "./AdminOrders.module.css";
import axios from "../../axios";
import { Order } from "../../Components/Admin/Order/Order";
import { AdminFilter } from "../AdminFilter/AdminFilter";
import fuzzysort from "fuzzysort";
import { useLoaderStore } from "../../stores/loader";
export interface AdminOrdersProps {}

export const AdminOrders: FC<AdminOrdersProps> = (props) => {
  const [orders, setOrders] = useState([]);
  const [ordersToShow, setOrdersToShow] = useState<any>([]);
  const [end, setEnd] = useState<any>(30);
  const firstRender = useRef(true);
  const loader = useRef<HTMLHeadingElement>(null);
  ///// for filter
  const [text, setText] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  //////for data
  const [data, setData] = useState({
    total: "",
    count: 0,
  });

  const startLoading = useLoaderStore((state) => state.startLoading);
  const finishLoading = useLoaderStore((state) => state.finishLoading);

  const handleObserver = (entities: IntersectionObserverEntry[]) => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    const target = entities[0];

    if (target.isIntersecting) {
      setEnd((prev: number) => prev + 30);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    });
    if (loader.current) {
      observer.observe(loader.current);
    }
  }, []);
  const getOrders = useCallback(async () => {
    startLoading();
    try {
      const data = await axios.get("orders/adminorders");
      console.log(data.data.data);
      setOrders(data.data.data);
      setOrdersToShow(data.data.data);
    } catch (e) {
      console.log(e);
    }
    finishLoading();
  }, []);

  useEffect(() => {
    getOrders();
    console.log(orders);
  }, [getOrders]);

  useEffect(() => {
    // Check if dateFrom is a valid date
    const isValidDateFrom = !isNaN(Date.parse(dateFrom));

    // Check if dateTo is a valid date
    const isValidDateTo = !isNaN(Date.parse(dateTo));

    if (isValidDateFrom && isValidDateTo) {
      // Both dateFrom and dateTo are valid, so filter based on both
      const filteredArray = orders.filter((obj: any) => {
        const objDate = new Date(obj.createdAt).toISOString().split("T")[0];
        return (
          objDate >= new Date(dateFrom).toISOString().split("T")[0] &&
          objDate <= new Date(dateTo).toISOString().split("T")[0]
        );
      });
      setOrdersToShow(filteredArray);
    } else if (isValidDateFrom) {
      // Only dateFrom is valid, so filter based on dateFrom
      const filteredArray = orders.filter((obj: any) => {
        const objDate = new Date(obj.createdAt).toISOString().split("T")[0];
        return objDate >= new Date(dateFrom).toISOString().split("T")[0];
      });
      setOrdersToShow(filteredArray);
    } else {
      // Handle the case when neither dateFrom nor dateTo is valid
      console.log("Invalid date(s)");

      setOrdersToShow(orders);
    }
  }, [dateFrom, dateTo]);

  useEffect(() => {
    let totalSum = 0;
    let totalCount = 0;

    // Loop through the ordersToShow array
    ordersToShow.forEach((order: any) => {
      if (order && order.totalPrice) {
        // Check if the order has a valid totalPrice property
        const orderPrice = parseFloat(order.totalPrice);
        if (!isNaN(orderPrice)) {
          // Add the order's price to the totalSum
          totalSum += orderPrice;
          // Increment the totalCount
          totalCount++;
        }
      }
    });

    setData({
      total: totalSum.toFixed(4),
      count: totalCount,
    });
  }, [ordersToShow]);

  console.log(end);

  useEffect(() => {
    if (text.length == 0) {
      setOrdersToShow(orders);
    } else {
      const v = fuzzysort.go(text, orders, {
        keys: ["userId.PharmacyCode", "userId.PharmacyName"],
      });
      const objArray = v.map((item) => item.obj);
      setOrdersToShow(objArray);
    }
  }, [text]);

  return (
    <div className={classes.Container}>
      <AdminFilter
        text={text}
        setText={setText}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
      />

      <div className={classes.DataContainer}>
        <p>
          لدينا {data.count} طلبيه بمجموع {data.total} جنيه
        </p>
      </div>

      {ordersToShow.slice(0, end).map((e: any) => (
        <Order
          createdAt={e.createdAt}
          finished={e.finished}
          orderNumber={e._id}
          products={e.products}
          totalPrice={e.totalPrice}
          userId={e.userId}
          key={e._id}
          status="done"
        />
      ))}

      <h2 style={{ textAlign: "center", marginTop: "5rem" }} ref={loader}></h2>
    </div>
  );
};
