import { FC, forwardRef, useCallback, useEffect, useState } from "react";
import classes from "./Cart.module.css";
import Grid from "@mui/material/Grid";
import { Product } from "../../Components/Product/Product";
import { useCartStore } from "../../stores/cart";
import { useProductStore } from "../../stores/products";
import { useOrderStore } from "../../stores/order";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useUserStore } from "../../stores/user";
export interface CartProps { }

const Alert = forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return (
      <MuiAlert
        className={classes.Message}
        elevation={6}
        ref={ref}
        variant="filled"
        {...props}
      />
    );
  },
);

export const Cart: FC<CartProps> = (props) => {
  const user = useUserStore((state) => state.user);
  const cart = useCartStore((state) => state.products);
  const totalPrice = useCartStore((state) => state.totalPrice);
  const products = useProductStore((state) => state.product);
  const createOrder = useOrderStore((state) => state.createOrder);
  const insufficientProducts = useOrderStore(
    (state) => state.insufficientProducts,
  );
  const [foundProducts, setFoundProducts] = useState<any>([]);

  const [open, setOpen] = useState(false);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const getItemsInCart = useCallback(() => {
    const itemsInCart = cart.map((cartItem) => {
      const product: any = products.find(
        (product) => product.id == cartItem.productId,
      );
      if (product) {
        return {
          productId: cartItem.productId,
          name: product.name,
          discount: product[`${user.type}`],
          quantity: product.quantity,
          before: product.originalPrice,
          after: product[`${user.type}Price`],
          orderQuantity: cartItem.orderQuantity,
        };
      }
      return null;
    });
    return itemsInCart.filter((item) => item !== null);
  }, [cart, products]);

  const puplishOrder = async () => {
    await createOrder();
    setOpen(true);
  };

  useEffect(() => {
    const productsInCart = getItemsInCart();
    setFoundProducts(productsInCart);
  }, [getItemsInCart]);

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        className={classes.Message}
      >
        <Alert
          className={classes.Message}
          onClose={handleClose}
          severity={insufficientProducts.length > 0 ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {insufficientProducts.length > 0
            ? "برجاء مراجعه الطلب"
            : "تم عمل الطلب بنجاح"}
        </Alert>
      </Snackbar>
      <Grid
        style={{ marginBottom: "1rem" }}
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 3, sm: 8, md: 12 }}
        direction={"row"}
        justifyContent="center"
      >
        {foundProducts.map((e: any) => {
          const insufficientProduct: any = insufficientProducts.find(
            (insuff: any) => insuff.productId == e.productId,
          );

          return (
            <Product
              key={e.productId}
              id={e.productId}
              isCart={true}
              title={e.name}
              after={e.after}
              before={e.before}
              discount={e.discount}
              quantity={e.quantity}
              orderQuantity={e.orderQuantity}
              insuffecient={!!insufficientProduct}
            />
          );
        })}
      </Grid>
      {cart.length > 0 ? (
        <div style={{ marginTop: "5rem" }}>
          <div className={classes.PriceContainer}>
            <h2>السعر النهائي</h2>
            <p>{totalPrice.toFixed(2)}ج</p>
          </div>
          <button onClick={puplishOrder} className={classes.Submit}>
            تأكيد
          </button>
        </div>
      ) : (
        <h1>قم بأضافه اصناف الى السله</h1>
      )}
    </>
  );
};
