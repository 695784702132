import { FC } from "react";
import Container from "@mui/material/Container";
import { Login as LoginComp } from "../../Components/Login/Login";
import { Navigate, Route, Routes } from "react-router-dom";
import { Policy } from "../Policy/Policy";
import { Home } from "../Home/Home";
import { LogoBar } from "../LogoBar/LogoBar";
export interface LoginProps {}

export const Login: FC<LoginProps> = (props) => {
  return (
    <>
      <LogoBar />
      <Container
        fixed
        disableGutters
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Routes>
          <Route Component={Home} path="/" />
          <Route Component={LoginComp} path="/login" />
          <Route Component={Policy} path="/privacy-policy" />
        </Routes>
      </Container>
    </>
  );
};
