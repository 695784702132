import React, { useEffect } from "react";
import classes from "./App.module.css";
import { Layout } from "./Containers/Layout/Layout";
import { Route, Routes, useLocation } from "react-router-dom";
import { Admin } from "./Containers/Admin/Admin";
import { useUserStore } from "./stores/user";
import { Login } from "./Containers/Login/Login";
import { StartScreen } from "./Containers/StartScreen/StartScreen";
import { useLoaderStore } from "./stores/loader";
import { CircularProgress } from "@mui/material";
import { auth } from "./Components/FirebaseLogin/firebase";
import { Policy } from "./Containers/Policy/Policy";
function App() {
  const { pathname } = useLocation();
  const getMe = useUserStore((state) => state.getMe);
  const user = useUserStore((state) => state.user);
  const shouldShowLogin = useUserStore((state) => state.shouldLogin);
  const done = useUserStore((state) => state.done);
  const loading = useLoaderStore((state) => state.loading);

  const shouldShowStartScreen = !done;
  const showLoginNow = shouldShowLogin && !loading;

  useEffect(() => {
    console.log(pathname.toLowerCase().startsWith("/admin"));
    if (!user.email) {
      console.log("adsadsadsadasdasdadassd");
      getMe();
    }
  }, [user]);

  return (
    <div className={classes.App}>
      {loading && (
        <div className={classes.SpinnerOverlay}>
          <CircularProgress size={"10rem"} className={classes.Spinner} />
        </div>
      )}
      {showLoginNow ? (
        <Login />
      ) : shouldShowStartScreen ? (
        <StartScreen />
      ) : pathname.toLowerCase().startsWith("/admin") ? (
        <>
          <Admin />
        </>
      ) : (
        <>
          <Layout />
        </>
      )}
    </div>
  );
}

export default App;
