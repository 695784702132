import { FC } from "react";
import { Link } from "react-router-dom";
import { LogoBar } from "../LogoBar/LogoBar";
import classes from "./Home.module.css";

export interface HomeProps {}

export const Home: FC<HomeProps> = (props) => {
  return (
    <div className={classes.Container}>
      <div className={classes.Container}>
        <h1>Welcome to Infinity Pharma Stores!</h1>
        <p>
          We are a digital platform that connects pharmacies with suppliers of
          pharmaceuticals, allowing them to easily purchase the products they
          need.
        </p>
        <h2>What We Do:</h2>
        <br />
        <ul className={classes.List}>
          <li>
            We provide a vast inventory of pharmaceuticals from reputable
            suppliers.
          </li>
          <li>
            Our platform allows pharmacies to place orders and manage their
            inventory with ease.
          </li>
          <li>
            We offer competitive pricing and flexible payment options to ensure
            that our customers can stay within budget.
          </li>
        </ul>
        <div className={classes.ButtonContainer}>
          <a
            href="https://play.google.com/store/apps/details?id=com.infinity.store&pcampaignid=web_share"
            className={classes.Button}
          >
            Download on Google Play Store
          </a>
          <a href="#" className={classes.Button}>
            Download on Apple App Store
          </a>
          <Link to="/login" className={classes.Button}>
            Go to Login Page
          </Link>
          <Link to="/privacy-policy" className={classes.Button}>
            Read Our Privacy Policy
          </Link>
        </div>
      </div>
    </div>
  );
};
