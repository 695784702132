import classes from "./NewClients.module.css";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import axios from "../../axios";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useLoaderStore } from "../../stores/loader";

export interface NewClientsProps {}

export const Clients: FC<NewClientsProps> = (props) => {
  const [clients, setClients] = useState([]);
  const [end, setEnd] = useState<any>(30);
  const firstRender = useRef(true);
  const loader = useRef<HTMLHeadingElement>(null);

  const startLoading = useLoaderStore((state) => state.startLoading);
  const finishLoading = useLoaderStore((state) => state.finishLoading);

  const getClients = useCallback(async () => {
    startLoading();
    try {
      const data = await axios.get("user");
      setClients(data.data.data);
    } catch (e) {
      console.log(e);
    }
    finishLoading();
  }, []);

  useEffect(() => {
    getClients();
  }, [getClients]);

  const handleObserver = (entities: IntersectionObserverEntry[]) => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    const target = entities[0];

    if (target.isIntersecting) {
      setEnd((prev: number) => prev + 30);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    });
    if (loader.current) {
      observer.observe(loader.current);
    }
  }, []);

  return (
    <div className={classes.Container}>
      <div className={classes.Count}>لدينا {clients.length} عميل </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="right">الصدليه</TableCell>
              <TableCell align="right">العنوان</TableCell>
              <TableCell align="right">الرقم</TableCell>
              <TableCell align="right">لايميل</TableCell>
              <TableCell align="right">الاسم</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.slice(0, end).map((row: any) => (
              <TableRow
                key={row._id}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell align="right">{row.PharmacyName}</TableCell>
                <TableCell align="right">{row.address}</TableCell>
                <TableCell align="right">{row.PhoneNumber}</TableCell>
                <TableCell align="right">{row.email}</TableCell>
                <TableCell scope="row">{row.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <h2 style={{ textAlign: "center", marginTop: "5rem" }} ref={loader}></h2>
    </div>
  );
};
