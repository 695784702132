import { FC } from 'react';
import classes from './GoogleButton.module.css';
import google from '../../google.png';
export interface GoogleButtonProps {
  click: any;
}

export const GoogleButton: FC<GoogleButtonProps> = (props) => {
  return (
    <img
      onClick={props.click}
      className={classes.Container}
      src={google}
      alt=""
    />
  );
};
