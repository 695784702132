import { FC } from "react";
import classes from "./AdminBar.module.css";
import { NavBarItem } from "../../Components/NavBar/NavBarItem";
import {
  BsPersonCheck,
  BsFillCalendarCheckFill,
  BsUpload,
} from "react-icons/bs";

import { GrUserNew } from "react-icons/gr";
export interface AdminBarProps {}

export const AdminBar: FC<AdminBarProps> = (props) => {
  return (
    <div className={classes.Container}>
      <NavBarItem to={"/admin"} title="Orders" Icon={BsFillCalendarCheckFill} />

      <NavBarItem to={"/admin/clients"} title="Clients" Icon={GrUserNew} />

      <NavBarItem to={"/admin/upload"} title="Upload" Icon={BsUpload} />
    </div>
  );
};
