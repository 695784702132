import { FC } from 'react';
import classes from './NavBarItem.module.css';
import { NavLink } from 'react-router-dom';

import { IconType } from 'react-icons';

export interface NavBarItemProps {
  Icon: IconType,
  title: string,
  to: string
}

export const NavBarItem: FC<NavBarItemProps> = (props) => {
  return (
    <NavLink className={({ isActive }) =>
      [isActive ? classes.NavItemActive : "", classes.NavItem].join(" ")
    } to={props.to} >
      <props.Icon className={classes.NavIcon} />
      <p>{props.title}</p>
    </NavLink>
  );
};        