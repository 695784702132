import { create } from 'zustand';

interface ILoaderStore {
  loading: boolean;
  startLoading: () => void;
  finishLoading: () => void;
}

export const useLoaderStore = create<ILoaderStore>((set) => ({
  loading: false,
  startLoading: () => set(() => ({ loading: true })),
  finishLoading: () => set(() => ({ loading: false })),
}));
