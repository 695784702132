import { FC, useState } from 'react';
import classes from './OrderCard.module.css';
import { Grid } from '@mui/material';
import { IProduct } from '../../stores/order';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export interface OrderCardProps {
  orderNumber: string;
  totalPrice: number;
  status: string;
  products: IProduct[];
}

export const OrderCard: FC<OrderCardProps> = (props) => {
  const [show, setShow] = useState(false);
  const click = () => {
    setShow(!show);
  };

  return (
    <div className={classes.Container}>
      <div className={classes.InfoContainer}>
        <p>{props.orderNumber}</p>
        <p>{props.totalPrice.toFixed(2)}ج</p>
        <button onClick={click} className={classes.Button}>
          {show ? 'اخفاء البيانات' : 'اظهار المزيد'}
        </button>
      </div>

      {show && (
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>الخصم</TableCell>
                <TableCell align="right">السعر</TableCell>
                <TableCell align="right">الكمية</TableCell>
                <TableCell align="right">الاسم</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.products.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0,
                    },
                  }}
                >
                  <TableCell scope="row">{row.discount}%</TableCell>
                  <TableCell align="right">{row.price}ج</TableCell>
                  <TableCell align="right">{row.quantity}</TableCell>
                  <TableCell align="right">{row.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};
