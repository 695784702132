import { FC } from 'react';
import classes from './StartScreen.module.css';
import logo from '../../logo.png';

export interface StartScreenProps {}

export const StartScreen: FC<StartScreenProps> = (props) => {
  return (
    <div className={classes.Container}>
      <img src={logo} alt="" />
    </div>
  );
};
