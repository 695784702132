import { FC, useState } from "react";
import classes from "./Login.module.css";
import { GoogleButton } from "../../Components/GoogleButton/GoogleButton";
import { signInWithGooglePopup } from "../../Components/FirebaseLogin/firebase";
import logo from "../../logo.png";
import { UserCredential } from "firebase/auth";
import axios from "../../axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Information } from "../../Containers/Information/Information";
import { Link, NavLink } from "react-router-dom";
export interface LoginProps {}

export const Login: FC<LoginProps> = (props) => {
  const [data, setData] = useState<UserCredential>();
  const [userExist, setUserExist] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const googleAuth = async () => {
    setLoading(true);
    const response = await signInWithGooglePopup();
    const getToken = await response.user.getIdTokenResult();
    localStorage.setItem("tokenId", getToken.token);
    setData(response);
    try {
      await axios.get("/auth/me");
      setUserExist(true);
      window.location.reload();
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };
  return (
    <div className={classes.Container}>
      {loading ? (
        <div className={classes.SpinnerOverlay}>
          <CircularProgress size={"10rem"} className={classes.Spinner} />
        </div>
      ) : data && !userExist ? (
        <div className={classes.Container}>
          <Information data={data} />
        </div>
      ) : (
        <div className={classes.Container}>
          <img style={{ marginBottom: "10rem" }} src={logo} alt="" />
          <GoogleButton click={googleAuth} />
        </div>
      )}
      <NavLink style={{}} to={"/privacy-policy"}>Our Privacy Policy</NavLink>
    </div>
  );
};
