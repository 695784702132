import { FC, useEffect, useRef, useState } from "react";
import classes from "./Products.module.css";
import { Product } from "../../Components/Product/Product";
import Grid from "@mui/material/Grid";
import { useProductStore } from "../../stores/products";
import { useCartStore } from "../../stores/cart";
import { SearchBar } from "../../Components/SearchBar/SearchBar";
import fuzzysort from "fuzzysort";
import { useUserStore } from "../../stores/user";

export interface ProductsProps { }

export const Products: FC<ProductsProps> = (props) => {
  const user = useUserStore((state) => state.user);
  const [end, setEnd] = useState<any>(30);
  const firstRender = useRef(true);
  const loader = useRef<HTMLHeadingElement>(null);
  const products = useProductStore((state) => state.product);
  const cart = useCartStore((state) => state.products);
  const [searchText, setSearchText] = useState<string>("");
  const [productSearch, setProductSearch] = useState<any>();

  const handleObserver = (entities: IntersectionObserverEntry[]) => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    const target = entities[0];

    if (target.isIntersecting) {
      setEnd((prev: number) => prev + 30);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    });
    if (loader.current) {
      observer.observe(loader.current);
    }
  }, []);

  useEffect(() => {
    setProductSearch(fuzzysort.go(searchText, products, { key: "name" }));
  }, [searchText]);

  const findCartItem = (productId: any) => {
    return cart.find((item) => item.productId == productId);
  };

  return (
    <>
      <SearchBar setSearchText={setSearchText} searchText={searchText} />
      <Grid
        style={{ marginBottom: "10rem" }}
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 3, sm: 8, md: 12 }}
        direction={"row"}
        justifyContent="center"
      >
        {searchText.length > 0
          ? productSearch.slice(0, end).map((e: any) => {
            const element = e.obj;
            const oP = element.originalPrice.toString().slice(0, 7);
            const cartItem = findCartItem(element.id);
            return (
              <Product
                orderQuantity={cartItem ? cartItem.quantity : 0}
                after={element[`${user.type}Price`] || element["gameaPrice"]}
                before={oP}
                discount={element[`${user.type}`] || element["gamea"]}
                isCart={!!cartItem}
                title={element.name}
                key={element.id}
                quantity={element.quantity}
                id={element.id}
              />
            );
          })
          : products.slice(0, end).map((e: any) => {
            const oP = e.originalPrice.toString().slice(0, 7);
            const cartItem = findCartItem(e.id);
            return (
              <Product
                orderQuantity={cartItem ? cartItem.orderQuantity : 0}
                after={e[`${user.type}Price`] || e["gameaPrice"]}
                before={oP}
                discount={e[`${user.type}`] || e["gamea"]}
                isCart={!!cartItem}
                title={e.name}
                key={e.id}
                quantity={e.quantity}
                id={e.id}
              />
            );
          })}
      </Grid>

      <h2 style={{ textAlign: "center", marginTop: "5rem" }} ref={loader}></h2>
    </>
  );
};
