import { FC } from "react";
import classes from "./Information.module.css";
import TextField from "@mui/material/TextField";
import { useUserStore } from "../../stores/user";
import { UserCredential } from "firebase/auth";

export interface InformationProps {
  data: UserCredential;
}

export const Information: FC<InformationProps> = (props) => {
  const createUser = useUserStore((state) => state.createUser);
  const getMe = useUserStore((state) => state.getMe);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      phName: { value: string };
      phone: { value: string };
      address: { value: string };
    };

    const pharmacyName = target.phName.value; // typechecks!
    const phoneNumber = target.phone.value; // typechecks!
    const address = target.address.value; // typechecks!
    createUser({
      pharmacyName,
      phoneNumber,
      address,
      email: props.data.user.email,
      id: props.data.user.uid,
      name: props.data.user.displayName,
    });
    getMe();
  };

  return (
    <form className={classes.Container} onSubmit={handleSubmit}>
      <TextField
        required
        InputProps={{
          style: { fontSize: "2rem" },
        }}
        InputLabelProps={{
          style: { fontSize: "2rem" },
        }}
        fullWidth
        id="phName"
        name="phName"
        label="اسم الصدليه"
        variant="outlined"
      />
      <TextField
        required
        InputProps={{
          style: { fontSize: "2rem" },
        }}
        InputLabelProps={{
          style: { fontSize: "2rem" },
        }}
        fullWidth
        id="phone"
        name="phone"
        label="رقم الهاتف"
        variant="outlined"
      />
      <TextField
        required
        InputProps={{
          style: { fontSize: "2rem" },
        }}
        InputLabelProps={{
          style: { fontSize: "2rem" },
        }}
        fullWidth
        id="address"
        name="address"
        label="العنوان"
        variant="outlined"
      />
      <input
        style={{ cursor: "pointer" }}
        type="submit"
        className={classes.button}
        value={"تأكيد"}
      />
    </form>
  );
};
