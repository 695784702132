import { FC } from "react";
import Container from "@mui/material/Container";
import classes from "./Admin.module.css";
import { Route, Routes } from "react-router-dom";
import { AdminOrders } from "../AdminOrders/AdminOrders";
import { AdminBar } from "../AdminBar/AdminBar";
import { useUserStore } from "../../stores/user";
import { NotAuthorized } from "../NotAuthorized/NotAuthorized";
import { Clients } from "../NewClients/NewClients";
import { Upload } from "../Upload/Upload";

export interface AdminProps {}

export const Admin: FC<AdminProps> = (props) => {
  const done = useUserStore((state) => state.done);
  const userEmail = useUserStore((state) => state.user.email);

  return done && userEmail === process.env.REACT_APP_ADMIN_EMAIL ? (
    <div style={{ paddingBottom: "15rem" }}>
      <AdminBar />

      <Container
        fixed
        disableGutters
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Routes>
          <Route Component={AdminOrders} path="/admin" />
          <Route Component={Clients} path="/admin/clients" />
          <Route Component={Upload} path="/admin/upload" />
        </Routes>
      </Container>
    </div>
  ) : (
    <NotAuthorized />
  );
};
