import { FC, useState } from "react";
import classes from "./Upload.module.css";
import { Button } from "@mui/material";
import axios from "../../axios";
import { useLoaderStore } from "../../stores/loader";

export interface UploadProps {}

export const Upload: FC<UploadProps> = (props) => {
  const [productsFile, setProductsFile] = useState<any>();
  const [error, setError] = useState("");
  const startLoading = useLoaderStore((state) => state.startLoading);
  const finishLoading = useLoaderStore((state) => state.finishLoading);
  function handleProductFile(e: any) {
    setError("");
    setProductsFile(e.target.files[0]);
  }

  async function handleProductUpload() {
    startLoading();
    if (!productsFile) {
      setError("Please choose file first. ");
      return;
    }

    let formData = new FormData();
    formData.append(`file`, productsFile);
    try {
      await axios.post("/user/uploadProducts", formData);
    } catch (e: any) {
      console.log(e);
    }
    setProductsFile(null);
    finishLoading();
  }

  return (
    <div className={classes.Container}>
      <div className={classes.Buttons}>
        <Button className={classes.Button} variant="outlined" component="label">
          اختيار ملف الاصناف
          <input
            id="file-input"
            name="file-input"
            accept=" .xlsx"
            type="file"
            hidden
            onChange={handleProductFile}
          />
        </Button>
        {productsFile && (
          <>
            <Button
              className={classes.Button}
              variant="contained"
              component="label"
              onClick={handleProductUpload}
            >
              ارسال
            </Button>
            <p className={classes.Message}>{productsFile.name}</p>
          </>
        )}
      </div>
    </div>
  );
};
