import { FC } from 'react';
import classes from './LogoBar.module.css';
import logo from '../../logo.png';
import { NavLink } from 'react-router-dom';
export interface LogoBarProps {}

export const LogoBar: FC<LogoBarProps> = (props) => {
  return (
    <NavLink to={'/'} className={classes.Container}>
      <img src={logo} alt="" />
    </NavLink>
  );
};
