import axios from "axios";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/v1/`,
  withCredentials: true,
});

const getToken = (): string | null => {
  // Replace this with the actual logic to get the token
  return localStorage.getItem("tokenId");
};

// Add a request interceptor to include the token in the Authorization header
instance.interceptors.request.use(
  async (config) => {
    const token = getToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
export default instance;
