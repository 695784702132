import React, { useEffect, useState, FC } from "react";
import classes from "./Layout.module.css";
import Container from "@mui/material/Container";
import { NavBar } from "../NavBar/NavBar";
import { Navigate, Route, Routes } from "react-router-dom";
import { Products } from "./../Products/Products";
import { Cart } from "./../Cart/Cart";
import { Orders } from "./../Orders/Orders";
import { useProductStore } from "../../stores/products";
import { useCartStore } from "../../stores/cart";
import { useOrderStore } from "../../stores/order";
import { LogoBar } from "./../LogoBar/LogoBar";
import { useUserStore } from "../../stores/user";

export interface LayoutProps {}

export const Layout: FC<LayoutProps> = (props) => {
  const getProducts = useProductStore((state) => state.getProducts);
  const getCart = useCartStore((state) => state.getCart);
  const getOrders = useOrderStore((state) => state.getOrders);
  const email = useUserStore((state) => state.user.email);

  useEffect(() => {
    getProducts();
    if (email) {
      getOrders();
      getCart();
    }
  }, []);

  return (
    <>
      <LogoBar />
      <NavBar />
      <Container
        fixed
        disableGutters
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Routes>
          <Route Component={Products} path="/" />
          <Route Component={Cart} path="/cart" />
          <Route Component={Orders} path="/orders" />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Container>
    </>
  );
};
