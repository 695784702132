import { FC } from 'react';
import classes from './PDFDocument.module.css';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  usePDF,
} from '@react-pdf/renderer';
import { IProduct } from '../../stores/order';

import { Font } from '@react-pdf/renderer';

import fontNoto from '../../Noto_Sans_Arabic/arabic.ttf';

Font.register({
  family: 'Noto',
  fonts: [{ src: fontNoto, fontWeight: 'normal' }],
});

function reverseNumbersInString(inputString: string) {
  const parts = inputString.split(/(\d+)/);
  const reversedParts = parts.map((part) => {
    if (/^\d+$/.test(part)) {
      return part.split('').reverse().join('');
    }
    return part;
  });

  const reversedString = reversedParts.join('');

  return reversedString;
}

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 20,
    fontSize: 15,
    fontFamily: 'Noto',
  },
  section: {
    fontSize: 15,
    margin: 10,
    fontFamily: 'Noto',
    padding: 10,
  },
  table: {
    fontSize: 15,
    width: '100%', // Set the table width to 100% of the parent container
    fontFamily: 'Noto',
    borderCollapse: 'collapse', // Ensures cells share borders
  },
  tableRow: {
    fontFamily: 'Noto',
    fontSize: 15,
    width: '100%',
    flexDirection: 'row',
  },
  tableCell: {
    width: '11%', // Adjust the width as needed for your layout
    padding: 1,
    fontSize: 15,
    fontFamily: 'Noto',
    border: '1px solid #000',
    textAlign: 'center', // Center-align text within cells
  },

  tableCellName: {
    fontSize: 15,
    width: '70%',
    padding: 1,
    fontFamily: 'Noto',
    border: '1px solid #000',
    textAlign: 'center',
  },

  tableCellPhone: {
    fontSize: 15,
    width: '20%',
    padding: 1,
    fontFamily: 'Noto',
    border: '1px solid #000',
    textAlign: 'center',
  },
  tableCode: {
    fontSize: 15,
    width: '50%',
    padding: 1,
    fontFamily: 'Noto',
    border: '1px solid #000',
    textAlign: 'center',
  },
});
const MyDoc = (data: any) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableCell}>
            <Text>الاجمالي</Text>
          </View>
          <View style={styles.tableCell}>
            <Text>التاريخ</Text>
          </View>
          <View style={styles.tableCellPhone}>
            <Text>الرقم</Text>
          </View>
          <View style={styles.tableCode}>
            <Text>اسم \ كود الصدليه</Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableCell}>
            <Text>ج{data.totalPrice.toFixed(2)}</Text>
          </View>
          <View style={styles.tableCell}>
            <Text>
              {new Date(data.createdAt).toISOString().split('T')[0]}
            </Text>
          </View>
          <View style={styles.tableCellPhone}>
            <Text>{data.userId.PhoneNumber}</Text>
          </View>
          <View style={styles.tableCode}>
            <Text>
              {data.userId.PharmacyCode ?? data.userId.PharmacyName}
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.section}>
        <Text>Order Details</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCell}>
              <Text>الخصم</Text>
            </View>
            <View style={styles.tableCell}>
              <Text>السعر</Text>
            </View>
            <View style={styles.tableCell}>
              <Text>الكميه</Text>
            </View>
            <View style={styles.tableCellName}>
              <Text>الاسم</Text>
            </View>
          </View>
          {data.products.map((item: IProduct, index: any) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCell}>
                <Text>{item.discount}%</Text>
              </View>
              <View style={styles.tableCell}>
                <Text>{item.price.toFixed(2)}ج</Text>
              </View>
              <View style={styles.tableCell}>
                <Text>{item.quantity}</Text>
              </View>

              <View style={styles.tableCellName}>
                <Text>{reverseNumbersInString(item.name)}</Text>
              </View>
            </View>
          ))}
        </View>
      </View>
    </Page>
  </Document>
);

export interface PDFDocumentProps {
  data: any;
}

export const PDFDocument: FC<PDFDocumentProps> = (props) => {
  const [instance, _] = usePDF({
    document: MyDoc(props.data.props),
  });

  if (instance.loading) return <div>Loading ...</div>;

  if (instance.error)
    return <div>Something went wrong: {instance.error}</div>;
  return (
    <a
      href={instance.url as string}
      download={`${
        props.data.props.userId.PharmacyCode ??
        props.data.props.userId.PharmacyName
      }----${
        new Date(props.data.props.createdAt)
          .toISOString()
          .split('T')[0]
      }.pdf`}
    >
      تحميل
    </a>
  );
};
