import { FC } from 'react';
import classes from './SearchBar.module.css';
import fuzzysort from 'fuzzysort';

import OutlinedInput from '@mui/material/OutlinedInput/OutlinedInput';
import IconButton from '@mui/material/IconButton/IconButton';
import { MdOutlineClear } from 'react-icons/md';

export interface SearchBarProps {
  setSearchText: any;
  searchText: any;
}

export const SearchBar: FC<SearchBarProps> = (props) => {
  const search = (e: any) => {
    props.setSearchText(e.target.value);
  };

  const handleClearSearch = () => {
    props.setSearchText('');
  };

  return (
    <div className={classes.Container}>
      <OutlinedInput
        className={classes.Input}
        fullWidth
        placeholder="Search"
        onChange={search}
        value={props.searchText}
      />
      <IconButton
        onClick={handleClearSearch}
        className={classes.Clear}
      >
        <MdOutlineClear size={20} />
      </IconButton>
    </div>
  );
};
