import { FC, useEffect, useState } from "react";
import classes from "./NumberInput.module.css";
import { useCartStore } from "../../stores/cart";
import { MdOutlineDoneOutline } from "react-icons/md";

export interface NumberInputProps {
  id: string;
  quantity: number;
  max: number;
  price: string;
}

export const NumberInput: FC<NumberInputProps> = (props) => {
  const [quantity, setQuantity] = useState(props.quantity);

  const addToCart = useCartStore((state) => state.addToCart);
  const removeFromCart = useCartStore((state) => state.removeFromCart);

  const submit = () => {
    if (props.quantity === quantity || quantity < 1) return;
    const desiredQuantity = quantity - props.quantity;
    if (desiredQuantity > 0) {
      addToCart(props.id, parseInt(desiredQuantity as unknown as string));
    } else if (desiredQuantity < 0) {
      removeFromCart(
        props.id,
        parseInt(Math.abs(desiredQuantity) as unknown as string),
      );
    }
  };

  return (
    <div className={classes.InputGroup}>
      <input
        type="number"
        value={quantity}
        name="quantity"
        onChange={(e: any) => setQuantity(e.target.value)}
        className={classes.QuantityField}
      />

      <MdOutlineDoneOutline
        className={classes.Icon}
        size={"40"}
        onClick={submit}
      />
    </div>
  );
};
