import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyApxar2K0cgalfo7qy3oc4MqgHo9CxQgxk",
  authDomain: "infinity-5a133.firebaseapp.com",
  projectId: "infinity-5a133",
  storageBucket: "infinity-5a133.appspot.com",
  messagingSenderId: "432328974869",
  appId: "1:432328974869:web:3d34bb85abe71c2e6c241c",
  measurementId: "G-2D6YFYR4W5",
};

const firebaseApp = initializeApp(firebaseConfig);
// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider();

// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({
  prompt: "select_account ",
});

export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
