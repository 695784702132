import { FC } from 'react';
import classes from './NotAuthorized.module.css';

export interface NotAuthorizedProps {}

export const NotAuthorized: FC<NotAuthorizedProps> = (props) => {
  return (
    <div className={classes.Container}>
      YOU ARE NOT AUTHORIZED TO USE THIS PAGE
    </div>
  );
};
