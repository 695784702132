import { FC } from "react";
import classes from "./SpanItem.module.css";

export interface SpanItemProps {
  title: string;
  value: string;
}

export const SpanItem: FC<SpanItemProps> = (props) => {
  return (
    <div className={classes.SpanItem}>
      <p style={{ marginBottom: "10px" }}>{props.title}</p>
      <p>{props.value}</p>
    </div>
  );
};
