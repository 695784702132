import { FC } from "react";
import classes from "./Orders.module.css";
import { OrderCard } from "../../Components/OrderCard/OrderCard";
import { useOrderStore } from "../../stores/order";

export interface OrdersProps {}

export const Orders: FC<OrdersProps> = (props) => {
  const orders = useOrderStore((state) => state.orders);
  return (
    <div className={classes.Container}>
      {orders.map((o) => (
        <OrderCard
          key={o._id}
          products={o.products}
          orderNumber={o._id}
          status="pending"
          totalPrice={o.totalPrice}
        />
      ))}

      {orders.length > 0 ? null : <h1>لا يوجد اوردرات ل عرضها</h1>}
    </div>
  );
};
