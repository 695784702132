import { FC, useEffect, useRef, useState } from "react";
import classes from "./Order.module.css";
import { IProduct } from "../../../stores/order";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "../../../axios";
import { PDFDocument } from "../../PDFDocument/PDFDocument";

export interface OrderProps {
  orderNumber: string;
  createdAt: string;
  totalPrice: number;
  status: string;
  products: IProduct[];
  userId: any;
  finished: boolean;
}

export const Order: FC<OrderProps> = (props) => {
  const [show, setShow] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const click = () => {
    setShow(!show);
  };
  const sendDone = async () => {
    await axios.get(`orders/orderdone/${props.orderNumber}`);
    setIsDisabled(true);
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <div className={classes.Container}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="right">تم</TableCell>
              <TableCell align="right">رقم الهاتف</TableCell>
              <TableCell align="right">التاريخ</TableCell>
              <TableCell align="right">الاجمالي</TableCell>
              <TableCell align="right">كود\اسم الصدليه</TableCell>
              <TableCell align="right">تحميل</TableCell>
              <TableCell align="right">البيانات</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              key={props.orderNumber}
              sx={{
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              <TableCell scope="row">
                <Checkbox
                  onChange={sendDone}
                  size="medium"
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 28,
                      color: "blue",
                    },
                  }}
                  defaultChecked={props.finished}
                  disabled={props.finished || isDisabled}
                  {...label}
                />
              </TableCell>
              <TableCell scope="row">{props.userId.PhoneNumber}</TableCell>
              <TableCell align="right">
                {new Date(props.createdAt).toISOString().split("T")[0]}
              </TableCell>
              <TableCell align="right">{props.totalPrice.toFixed(2)}</TableCell>
              <TableCell align="right">
                {props.userId.PharmacyCode ?? props.userId.PharmacyName}
              </TableCell>
              <TableCell align="right">
                <PDFDocument data={{ props }} />
              </TableCell>
              <TableCell align="right">
                <button onClick={click} className={classes.Button}>
                  {show ? "اخفاء " : "اظهار "}
                </button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {show && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>الخصم</TableCell>
                <TableCell align="right">السعر</TableCell>
                <TableCell align="right">الكمية</TableCell>
                <TableCell align="right">الاسم</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.products.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell scope="row">{row.discount}%</TableCell>
                  <TableCell align="right">{row.price}ج</TableCell>
                  <TableCell align="right">{row.quantity}</TableCell>
                  <TableCell align="right">{row.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};
