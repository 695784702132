import { FC, useState } from 'react';
import classes from './AdminFilter.module.css';
import { SearchBar } from '../../Components/SearchBar/SearchBar';
import { TextField } from '@mui/material';
export interface AdminFilterProps {
  text: string;
  setText: any;
  setDateFrom: any;
  setDateTo: any;
}

export const AdminFilter: FC<AdminFilterProps> = (props) => {
  return (
    <div className={classes.Container}>
      <SearchBar
        searchText={props.text}
        setSearchText={props.setText}
      />
      <TextField
        id="date"
        label="from"
        type="date"
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
          style: { fontSize: '2rem' },
        }}
        InputProps={{
          style: { fontSize: '2rem' },
        }}
        onChange={(e: any) => props.setDateFrom(e.target.value)}
      />
      <TextField
        id="date"
        label="to"
        type="date"
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
          style: { fontSize: '2rem' },
        }}
        InputProps={{
          style: { fontSize: '2rem' },
        }}
        onChange={(e: any) => props.setDateTo(e.target.value)}
      />
    </div>
  );
};
