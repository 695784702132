import { create } from "zustand";
import axios from "../axios";
import { useCartStore } from "./cart";
import { useLoaderStore } from "./loader";
import { useProductStore } from "./products";

export interface IProduct {
  discount: number;
  name: string;
  price: number;
  productId: string;
  quantity: number;
}

interface IOrder {
  _id: string;
  totalPrice: number;
  products: IProduct[];
}

interface IOrderStore {
  orders: IOrder[];
  insufficientProducts: IOrder[];
  getOrders: () => void;
  createOrder: () => void;
}

export const useOrderStore = create<IOrderStore>((set) => ({
  orders: [],
  insufficientProducts: [],
  getOrders: async () => {
    try {
      const data = await axios.get("orders");
      set(() => ({ orders: data.data.data }));
    } catch (e: any) {
      console.log(e.response);
    }
  },
  createOrder: async () => {
    useLoaderStore.getState().startLoading();

    try {
      await axios.post("orders");
      useCartStore.getState().emptyCart();
      useOrderStore.getState().getOrders();
      useProductStore.getState().getProducts();
    } catch (e: any) {
      set(() => ({ insufficientProducts: e.response.data.data }));
      console.log(e.response.data);
    }
    useLoaderStore.getState().finishLoading();
  },
}));
