import { CSSProperties, FC, useState } from "react";
import classes from "./Product.module.css";
import { SpanItem } from "../SpanItem/SpanItem";
import Grid from "@mui/material/Grid";
import { BsFillCartPlusFill, BsFillTrash3Fill } from "react-icons/bs";
import { NumberInput } from "../NumberInput/NumberInput";
import { useCartStore } from "../../stores/cart";
import { useUserStore } from "../../stores/user";
export interface ProductProps {
  id: string;
  title: string;
  isCart: boolean;
  after: string;
  before: string;
  discount: string;
  quantity: number;
  orderQuantity: number;
  insuffecient?: boolean;
}

export const Product: FC<ProductProps> = (props) => {
  const addToCart = useCartStore((state) => state.addToCart);

  const deleteItemFromCart = useCartStore((state) => state.removeFromCart);

  const add = () => {
    addToCart(props.id, 1);
  };

  const deleteItem = () => {
    deleteItemFromCart(props.id, props.orderQuantity);
  };

  return (
    <Grid item xs={2.8} sm={4} md={4}>
      <div className={classes.Container}>
        <p style={{ marginBottom: "2rem" }}>{props.title}</p>
        <div className={classes.Spans}>
          <SpanItem
            title="بعد "
            value={props.after.toString().slice(0, 5) + "ج"}
          />
          <SpanItem
            title="الخصم"
            value={props.discount.toString().slice(0, 4) + "%"}
          />
          <SpanItem
            title="سعر الجمهور "
            value={props.before.toString().slice(0, 5) + "ج"}
          />
        </div>

        {props.insuffecient ? (
          <p style={{ color: "red", fontSize: "1.9rem" }}>
            هذه الكميه لم تعد متاحه
          </p>
        ) : null}

        {props.isCart ? (
          <div className={classes.inCart}>
            <BsFillTrash3Fill
              onClick={deleteItem}
              className={classes.Icon}
              color="red"
              size={"40"}
            />
            <NumberInput
              price={props.after}
              id={props.id}
              quantity={props.orderQuantity || 0}
              max={props.quantity}
            />
          </div>
        ) : (
          props.quantity > 0 && (
            <BsFillCartPlusFill
              onClick={add}
              className={classes.Icon}
              size={"40"}
            />
          )
        )}
      </div>
    </Grid>
  );
};
