import { FC } from "react";
import classes from "./Policy.module.css";
export interface PolicyProps {}

export const Policy: FC<PolicyProps> = (props) => {
  return (
    <div className={classes.container}>
      <h2>Privacy Policy</h2>
      <p>
        1. Introduction Welcome to Infinity Pharma Stores. Your privacy is one
        of utmost importance to us. This Privacy Policy outlines how we collect,
        use, and protect your personal information when you use our mobile
        application ("App") and any related services ("Services"). By using our
        App, you agree to the collection and use of information in accordance
        with this policy.
      </p>

      <h3>2. Information We Collect</h3>
      <ul className={classes.infoList}>
        <li>
          Personal Information: When you register or use our App, we may collect
          personal information such as your name, email address, phone number,
          and other contact details.
        </li>
        <li>
          Health-Related Information: We may collect information related to your
          prescriptions, medications, and other health-related details to
          provide you with our Services.
        </li>
        <li>
          Payment Information: If you make purchases through the App, we may
          collect payment information such as your credit card details and
          billing address.
        </li>
      </ul>

      <h3>3. How We Use Your Information</h3>
      <p>
        Provide Services: We use your personal and health-related information to
        provide you with our Services, such as processing orders and delivering
        medications.
      </p>
      <ul className={classes.infoList}>
        <li>
          Improve the App: We use usage data to improve our App's functionality,
          user experience, and security.
        </li>
        <li>
          Communication: We may use your contact information to send you
          important updates, promotions, and information about your orders.
        </li>
      </ul>

      <h3>4. Sharing Your Information</h3>
      <p>
        Service Providers: We may share your information with third-party
        service providers who assist us in delivering our Services (e.g.,
        payment processors, delivery services).
      </p>
      <p>
        Legal Requirements: We may disclose your information if required by law
        or in response to a legal request, such as a court order or subpoena.
      </p>

      <h3>5. Security of Your Information</h3>
      <p>
        We take reasonable measures to protect your personal information from
        unauthorized access, use, or disclosure.
      </p>

      <h3>6. Your Rights</h3>
      <ul className={classes.infoList}>
        <li>
          Access and Correction: You have the right to access and correct the
          personal information we hold about you.
        </li>
        <li>
          Deletion: You may request the deletion of your personal information,
          subject to certain legal obligations.
        </li>
      </ul>

      <h3>7. Children's Privacy</h3>
      <p>
        Our App is not intended for children under the age of 13. We do not
        knowingly collect personal information from children under 13.
      </p>

      <h3>8. Changes to This Privacy Policy</h3>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be
        posted on this page, and the "Last Updated" date will be revised.
      </p>

      <h3>9. Contact Us</h3>
      <p>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at: Email: shamsstore88@gmail.com
      </p>
    </div>
  );
};
