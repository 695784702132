import { create } from "zustand";
import axios from "../axios";
import { useLoaderStore } from "./loader";

interface ICartProduct {
  quantity: number;
  orderQuantity: number;
  name: string;
  productId: string;
  price: string;
  discount: string;
  before: string;
}

interface ICartStore {
  products: ICartProduct[];
  totalPrice: number;
  getCart: () => void;
  emptyCart: () => void;
  addToCart: (id: string, quantity: number) => void;
  removeFromCart: (id: string, quantity: number) => void;
}

export const useCartStore = create<ICartStore>((set) => ({
  products: [],
  totalPrice: 0,
  getCart: async () => {
    try {
      const data = await axios.get("cart");
      set({
        products: data.data.data.products,
        totalPrice: data.data.data.totalPrice,
      });
    } catch (e: any) {
      console.log(e.response);
    }
  },
  addToCart: async (id, quantity) => {
    useLoaderStore.getState().startLoading();
    const data = await axios.post("cart", {
      productId: id,
      quantity,
    });
    set({
      products: data.data.data.products,
      totalPrice: data.data.data.totalPrice,
    });
    useLoaderStore.getState().finishLoading();
  },
  removeFromCart: async (id, quantity) => {
    useLoaderStore.getState().startLoading();
    const data = await axios.patch("cart", {
      productId: id,
      quantity,
    });
    set({
      products: data.data.data.products,
      totalPrice: data.data.data.totalPrice,
    });
    useLoaderStore.getState().finishLoading();
  },
  emptyCart: () => {
    set((state) => ({ totalPrice: 0, products: [] }));
  },
}));
