import { FC } from 'react';
import classes from './NavBar.module.css';
import { AiFillHome } from "react-icons/ai";
import { NavBarItem } from '../../Components/NavBar/NavBarItem';
import { BsFillCalendarCheckFill, BsFillCartFill } from "react-icons/bs"
export interface NavBarProps {

}

export const NavBar: FC<NavBarProps> = (props) => {
  return (
    <div className={classes.Container}>
      <NavBarItem to={"/"} title='Home' Icon={AiFillHome} />
      <NavBarItem to={"/cart"} title='Cart' Icon={BsFillCartFill} />
      <NavBarItem to={"/orders"} title='Orders' Icon={BsFillCalendarCheckFill} />
    </div>
  );
};